import { jsPDF } from "jspdf";
import "jspdf-autotable";

export default {
  name: "DetailHistoryAngsuran",
  data() {
    return {
      property: {
        accessories: {
          day: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mei",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Des",
          ],
        },
        filterDto: {
          loanAccountNumber: "",
        },
        listElement: {
          historyAngsuran: {
            rows: 0,
            perPage: 10,
            currentPage: 0,
            message: "",
            downloading: false,
          },
        },
        modal: {
          showModalPreview: false,
        },
      },
      dataForm: {
        userId: "",
        officeId: "",
        userName: "",
        userCode: "",
        date: "",
        officeName: "",
        dateFrom: "",
        dateTo: "",
        exportContent: "",
        exportToExcel: {
          data: [],
          header: [],
        },
        historyAngsuran: "",
      },
      options: {
        users: [],
        cabangs: [],
        eksport: [],
      },
      table: {
        headers: {
          historyAngsuran: [
            {
              key: "officeName",
              label: "Cabang",
              tdClass: "list-daily_transaction-body-table",
              thClass: "list-daily_transaction-header-table text-white",
            },
            {
              key: "journalCode",
              label: "Kode Transaksi",
              tdClass: "list-daily_transaction-body-table",
              thClass: "list-daily_transaction-header-table text-white",
            },
            {
              key: "creditCoaCode",
              label: "Akun",
              tdClass: "list-daily_transaction-body-table",
              thClass: "list-daily_transaction-header-table text-white",
            },
            {
              key: "creditCoaName",
              label: "Nama Akun",
              tdClass: "list-daily_transaction-body-table",
              thClass: "list-daily_transaction-header-table text-white",
            },
            {
              key: "officeName",
              label: "creditDescDetail",
              tdClass: "list-daily_transaction-body-table",
              thClass: "list-daily_transaction-header-table text-white",
            },
            {
              key: "officeName",
              label: "Dokumen",
              tdClass: "list-daily_transaction-body-table",
              thClass: "list-daily_transaction-header-table text-white",
            },
            {
              key: "debtNominal",
              label: "Mutasi Debet",
              tdClass: "list-daily_transaction-body-table",
              thClass: "list-daily_transaction-header-table text-white",
            },
            {
              key: "creditNominal",
              label: "Mutasi Kredit",
              tdClass: "list-daily_transaction-body-table",
              thClass: "list-daily_transaction-header-table text-white",
            },
          ],
        },
        data: {
          historyAngsuran: [],
        },
      },
    };
  },
  methods: {
    handleCatchErrorHistoryAngsuran(error) {
      this.table.data.historyAngsuran = [];
      this.property.listElement.historyAngsuran.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async getHistoryInstallmentData() {
      this.table.data.historyAngsuran = [];
      this.property.listElement.historyAngsuran.downloading = true;
      this.dataForm.historyAngsuran = "";
      // this.dataForm.exportContent = "";
      // this.dataForm.exportToExcel.data = [];
      // this.dataForm.exportToExcel.header = [];
      const timeout = 500;
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.routeToPageListHistoryAngsuran();
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "loan",
            reqUrl:
              "loan-installment/history/" +
              this.property.filterDto.loanAccountNumber,
          });
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.dataForm.historyAngsuran = resp.data.data;
              this.getCabangData(this.dataForm.officeId);
            } else {
              this.property.listElement.historyAngsuran.message =
                resp.data.message;
            }
            console.log(this.dataForm.historyAngsuran);
          }, timeout);
        } catch (error) {
          setTimeout(() => {
            this.handleCatchErrorHistoryAngsuran(error);
          }, timeout);
        } finally {
          setTimeout(() => {
            this.property.listElement.historyAngsuran.downloading = false;
          }, timeout);
        }
      }
    },
    handleChangePilihEksport() {
      this.dataForm.exportToExcel.data = [];
      this.dataForm.exportToExcel.header = [];
      if (this.dataForm.exportContent === "EXCEL") {
        this.table.headers.historyAngsuran.map((index) => {
          const field = index.key;
          const label = `${index.label}`;
          this.dataForm.exportToExcel.header.push({ field, label });
        });
        this.table.data.historyAngsuran.map((index) => {
          const officeName = index.officeName;
          const transactionDate = index.transactionDate;
          const documentProof = index.documentProof;
          const referenceNumber = index.referenceNumber;
          const debitAccountNumber = index.debitAccountNumber;
          const creditAccountNumber = index.creditAccountNumber;
          const description = index.description;
          const transactionCode = index.transactionCode;
          const debitNominal = `${
            index.debitNominal
              ? index.debitNominal
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`;
          const creditNominal = `${
            index.creditNominal
              ? index.creditNominal
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`;
          this.dataForm.exportToExcel.data.push({
            officeName,
            transactionDate,
            documentProof,
            referenceNumber,
            debitAccountNumber,
            creditAccountNumber,
            description,
            transactionCode,
            debitNominal,
            creditNominal,
          });
        });
      } else {
        this.dataForm.exportToExcel.header = [];
        this.dataForm.exportToExcel.data = [];
      }
    },
    handleExportHistoryAngsuranToPdf() {
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: "letter",
      });
      const headerFinal = [];
      const header = this.table.headers.historyAngsuran.map((index) => {
        headerFinal.push(index.label);
      });
      const finalDataTable = [];
      var numbering = 1;
      const loopDataTable = this.table.data.historyAngsuran.map((index) => {
        finalDataTable.push([
          index.officeName,
          index.journalCode,
          index.creditCoaCode,
          index.creditCoaName,
          index.creditDescDetail,
          `-`,
          `${
            index.debtNominal
              ? index.debtNominal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
          `${
            index.creditNominal
              ? index.creditNominal
                  .toFixed(2)
                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")
              : 0
          }`,
        ]);
      });
      var pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

      doc
        .setFontSize(10)
        .text(`Laporan Transaksi Harian `, pageWidth / 2 - 1.1, 1);
      doc
        .setFontSize(8)
        .text(
          `Tanggal     :  ${this.dataForm.dateFrom} s/d ${this.dataForm.dateTo}`,
          0.55,
          2.1
        );
      doc
        .setFontSize(8)
        .text(`Cabang     :   ${this.dataForm.officeName}`, 0.55, 2.3);
      doc
        .setFontSize(8)
        .text(`User          :  ${this.dataForm.userName}`, 0.55, 1.9);
      doc.autoTable({
        columnStyles: {
          0: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            minCellWidth: 0.8,
            fontStyle: "normal",
          },
          1: {
            halign: "center",
            fontSize: 6,
            minCellWidth: 0.7,
            font: "courier",
            fontStyle: "normal",
          },
          2: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          3: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          4: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          5: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          6: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          7: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          8: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          9: {
            halign: "center",
            fontSize: 6,
            font: "courier",
            fontStyle: "normal",
          },
          10: {
            halign: "center",
            fontSize: 6,

            font: "courier",
            fontStyle: "normal",
          },
        },
        headStyles: {
          halign: "center",
          fontSize: 6.4,
          fontStyle: "bold",
          font: "normal",
        },
        head: [headerFinal],
        theme: "striped",
        body: finalDataTable,
        margin: { top: 2.6 },
        halign: "center",
        didDrawPage: function(data) {
          data.settings.margin.top = 0.9;
        },
      });
      // doc.save("Laporan Transaksi Harian.pdf");
      doc.autoPrint();

      const hiddFrame = document.createElement("iframe");
      hiddFrame.style.position = "fixed";
      // "visibility: hidden" would trigger safety rules in some browsers like safari，
      // in which the iframe display in a pretty small size instead of hidden.
      // here is some little hack ~
      hiddFrame.style.width = "1px";
      hiddFrame.style.height = "1px";
      hiddFrame.style.opacity = "0.01";
      const isSafari = /^((?!chrome|android).)*safari/i.test(
        window.navigator.userAgent
      );
      if (isSafari) {
        // fallback in safari
        hiddFrame.onload = () => {
          try {
            hiddFrame.contentWindow.document.execCommand("print", false, null);
          } catch (e) {
            hiddFrame.contentWindow.print();
          }
        };
      }
      hiddFrame.src = doc.output("bloburl");
      document.body.appendChild(hiddFrame);
    },
    async getCabangData(id) {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "parameter",
          reqUrl: "office/" + id,
        });
        if (resp.data.code === "SUCCESS") {
          this.dataForm.officeName = resp.data.data.officeName;
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : `Terjadi Kesalahan `,
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    getIdentityFromSession() {
      const getTokenFromSession = sessionStorage.getItem("LOAN_ACCOUNT_NUMBER");
      if (getTokenFromSession) {
        this.property.filterDto.loanAccountNumber = getTokenFromSession;
        this.getHistoryInstallmentData();
      }
    },
    routeToPageListHistoryAngsuran() {
      this.$router.push("list");
    },
    destroySession() {
      sessionStorage.removeItem("LOAN_ACCOUNT_NUMBER");
    },
  },
  mounted() {
    this.getIdentityFromSession();
  },
  destroyed() {
    this.destroySession();
  },
};
